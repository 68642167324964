export function AppPreviewMode(props: Record<string, any>) {
  if (props.isPreviewMode)
    return (
      <div className="fixed bottom-0 right-0 rounded-tl-xl bg-red-100 px-3 py-1 text-body-xs text-black/60">
        preview mode
      </div>
    )

  return null
}
