import Script from "next/script"
import { useEffect, useState } from "react"

declare global {
  interface Window {
    Cookiebot: {
      consent: {
        marketing: boolean
        necessary: boolean
        preferences: boolean
        statistics: boolean
        stamp: string
      }
      hasResponse: boolean
      doNotTrack: boolean
    }
  }
}

export interface GtmScriptProps {
  gtmId: string
}

export const GtmScript = ({ gtmId }: GtmScriptProps) => {
  const [showGtm, setShowGtm] = useState(() => getShowGTM())

  // add listener to user's consent acceptance
  useEffect(() => {
    const handler = () => setShowGtm(getShowGTM())
    window.addEventListener("CookiebotOnConsentReady", handler, false)
    return () => window.removeEventListener("CookiebotOnConsentReady", handler, false)
  }, [])

  // skip rendering if GTM will not be shown
  if (!showGtm) return null
  return (
    <Script
      id="app-gtm-script"
      strategy="afterInteractive"
    >{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${gtmId}');`}</Script>
  )
}

function getShowGTM() {
  // detect existence of Cookiebot object
  if (typeof window !== "undefined" && "Cookiebot" in window) {
    // we respect user's do not track request
    if (window.Cookiebot.doNotTrack === true) {
      console.warn("Detected DNT preference. GTM script will not be added.")
      return false
    }

    // only enable GTM if user has specifically granted the "statistics" consent
    if (window.Cookiebot.consent?.statistics === false) {
      console.warn("Statistics consent was not given. GTM script will not be added.")
      return false
    }
  }

  return true
}
