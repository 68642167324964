import { withPasswordProtect } from "next-password-protect"
import type { AppProps } from "next/app"

import { Analytics } from "@vercel/analytics/react"

import { GtmScript } from "@/lib/components/scripts"
import { AppPreviewMode } from "@/lib/components/shared/preview-mode"
import "@/styles/globals.css"

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Component {...pageProps} />
      <AppPreviewMode {...pageProps} />
      <Analytics />

      {process.env.NEXT_PUBLIC_GTM_CODE && <GtmScript gtmId={process.env.NEXT_PUBLIC_GTM_CODE} />}
    </>
  )
}

export default process.env.PASSWORD_PROTECT === "true"
  ? withPasswordProtect(MyApp, {
      loginApiUrl: "/api/beta-login",
      checkApiUrl: "/api/beta-password-check",
    })
  : MyApp
